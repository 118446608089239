import React, { useState } from "react"
import { Form, Button } from "react-bootstrap"
import axios from "axios"
import { MdSwapHoriz } from "react-icons/md"
import { Link } from "gatsby"

const hostUrl = process.env.GATSBY_BACKEND_HOST

interface Props {
  purpose: "Registration" | "Collaboration"
}

const IndustryForm = ({ purpose }: Props) => {
  const [formState, setFormState] = useState({
    validated: false,
    submitted: false,
    success: false,
    error: "",
    organization: "",
    focus: "Data",
    focusOther: false,
    role: "",
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  })

  const endpoint =
    purpose === "Registration"
      ? `${hostUrl}/fua/registration/industrial/apply`
      : `${hostUrl}/fua/collaboration/industrial/apply`

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      event.preventDefault()
      event.stopPropagation()
      setFormState({ ...formState, validated: true })
      axios
        .post(endpoint, {
          firstName: formState.firstName,
          lastName: formState.lastName,
          email: formState.email,
          message: formState.message,
          organization: formState.organization,
          role: formState.role,
          focus: formState.focus,
        })
        .then(res => {
          setFormState({ ...formState, submitted: true, success: true })
        })
        .catch(err => {
          console.log(err)
          setFormState({ ...formState, submitted: true, error: err })
        })
    }

    setFormState({ ...formState, validated: true })
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "focus" && e.target.value === "Other") {
      setFormState({ ...formState, focusOther: true, focus: "" })
    } else {
      setFormState({ ...formState, [e.target.name]: e.target.value })
    }
  }

  const removeSpecify = () => {
    setFormState({ ...formState, focusOther: false, focus: "Data" })
  }

  return (
    <Form
      className="normal-form"
      noValidate
      validated={formState.validated}
      onSubmit={handleSubmit}
    >
      <div className="two-input-line">
        <Form.Group controlId="formBasicFirstName">
          <Form.Label>Organization</Form.Label>
          <Form.Control
            type="text"
            value={formState.organization}
            onChange={handleChange}
            name="organization"
            placeholder="Organization"
            required
          />
        </Form.Group>

        {formState.focusOther ? (
          <Form.Group controlId="formBasicOrganization">
            <Form.Label>
              <Link to="/focus" title="Read About Our Focus Areas">
                Focus Area
              </Link>
              <MdSwapHoriz
                className="swap-icon"
                onClick={removeSpecify}
                title="Revert To Dropdown"
              />
            </Form.Label>
            <Form.Control
              type="text"
              name="focus"
              value={formState.focus}
              placeholder="Please Specify"
              onChange={handleChange}
              required
            />
          </Form.Group>
        ) : (
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>
              <Link to="/focus" title="Read About Our Focus Areas">
                Focus Area
              </Link>
            </Form.Label>
            <Form.Control
              name="focus"
              as="select"
              value={formState.focus}
              onChange={handleChange}
              required
            >
              <option>Data</option>
              <option>SciML</option>
              <option>System Software</option>
              <option>Hardware</option>
              <option>Other</option>
            </Form.Control>
          </Form.Group>
        )}
      </div>
      <div className="two-input-line">
        <Form.Group controlId="formBasicFirstName">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            type="text"
            value={formState.firstName}
            onChange={handleChange}
            name="firstName"
            placeholder="First Name"
            required
          />
        </Form.Group>

        <Form.Group controlId="formBasicLastName">
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            type="text"
            value={formState.lastName}
            onChange={handleChange}
            name="lastName"
            placeholder="Last Name"
            required
          />
        </Form.Group>
      </div>

      <div className="two-input-line">
        <Form.Group controlId="formBasicRole">
          <Form.Label>Role</Form.Label>
          <Form.Control
            type="text"
            value={formState.role}
            onChange={handleChange}
            name="role"
            placeholder="Role"
            required
          />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            value={formState.email}
            onChange={handleChange}
            name="email"
            placeholder="Email"
            required
          />
        </Form.Group>
      </div>

      <Form.Group controlId="exampleForm.ControlTextarea1">
        <Form.Label>Message</Form.Label>
        <Form.Control
          as="textarea"
          value={formState.message}
          onChange={handleChange}
          name="message"
          rows={3}
          required
        />
      </Form.Group>

      {formState.submitted ? (
        formState.success ? (
          <p>
            Thank you! Somebody on our team will be in touch with you shortly.
          </p>
        ) : (
          <Button variant="danger">Error</Button>
        )
      ) : (
        <Button type="submit" variant="primary">
          Submit
        </Button>
      )}
    </Form>
  )
}

export default IndustryForm
