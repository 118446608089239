import { Link } from "gatsby"
import React from "react"
import IndustryForm from "../components/forms/industry"
import Layout from "../components/layout"
import SEO from "../components/seo"
const industry = require("../images/industry.jpg")

const IndustryPage = () => (
  <Layout>
    <SEO title="Industry" description="" lang="en" meta={[]} />
    <div className="title-banner industry">
      <h1>Industry</h1>
      <h3>Partnering with companies to promote research and innovation.</h3>
    </div>

    {/* Industry Section */}
    <section className="bg-darkened">
      <div className="container">
        <div className="row d-flex align-items-end">
          <div className="col-md-6 align-self-stretch d-flex flex-column justify-content-between">
            <h3 className="bold">Working with Flapmax</h3>
            <p>
              Our strong academic tie with top-class research universities
              allows us to be at the forefront of innovation. With access to top
              talent, we will work with you to explore and solve the deep
              technical challenges within your organization.
            </p>

            <img src={industry} alt="" className="img-fluid" />
          </div>
          <div className="col-md-6">
            <IndustryForm purpose="Collaboration" />
          </div>
        </div>
      </div>

      <div className="bg-darkened">
        <div className="container pt-5">
          <h3>Related Links</h3>
          <ul>
            <li>
              <Link to="/educator">Educator Page</Link>
            </li>
            <li>
              <Link to="/student">Student Page</Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndustryPage
